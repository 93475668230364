import React from "react";

interface Props {
	bgColor?: string;
}

const CirculatDot: React.FC<Props> = ({ bgColor = "bg-iceBlue" }) => {
	return (
		<span
			className={`translation-all mr-1 inline-block h-fit w-fit rounded-full 
        ${bgColor}
        p-[7px] duration-500  hover:p-[6px]`}
		></span>
	);
};

export default CirculatDot;
