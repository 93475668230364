import React, { useEffect } from "react";
import Container from "../components/Container";
import ParaText from "../components/ParaText";
import { useParams } from "react-router-dom";
import { adminRequest } from "../requestMethods";
import { toast } from "react-hot-toast";
import { EventType } from "./EventsPage";
import { useUserStore } from "../states/userState";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GalleryCard from "../components/GalleryCard";

type Props = {};

function SingleEvent({}: Props) {
	const [event, setEvent] = React.useState<EventType | null>(null);
	const { id } = useParams();
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	//get user from usestate
	const { user } = useUserStore();

	//getsingle event from backend
	const getEvent = async (id: string) => {
		//get single event from backend
		try {
			const { data } = await adminRequest.get(`/events/${id}`);
			setEvent(data.data);
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
			console.log(err);
		}
	};
	useEffect(() => {
		if (id) getEvent(id);
	}, []);

	const deleteEvent = async (id: string) => {
		let ans = prompt("Are you sure you want to delete this event?");
		if (ans?.toLowerCase() != "yes") return;
		try {
			const { data } = await adminRequest.delete(`/events/${id}`);
			toast.success(data.message);
			navigate("/dashboard");
		} catch (err: any) {
			console.log(err);
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
		}
	};

	return (
		<div>
			<Container className="px-0">
				<div className="relative max-h-[500px] w-full overflow-hidden bg-cover bg-center bg-no-repeat md:h-[520px]">
					<div className="absolute left-3 top-3 z-10 flex flex-col bg-white px-4 py-3">
						<span className="text-aquaBlue">{event?.date}</span>
					</div>
					{user && user?.isAdmin && id && (
						<>
							<div
								onClick={() => deleteEvent(id)}
								className="absolute right-3 top-3 z-10 flex cursor-pointer flex-col rounded-sm bg-white px-4 py-3 text-rose-600 transition-all hover:bg-rose-600 hover:text-white"
							>
								Delete
							</div>
							<div
								onClick={() =>
									navigate(`/dashboard/update-event/${id}`)
								}
								className="absolute right-3 top-16 z-10 flex cursor-pointer flex-col rounded-sm bg-white px-4 py-3 text-aquaBlue transition-all hover:bg-aquaBlue hover:text-white"
							>
								Edit
							</div>
						</>
					)}

					<img
						src={event?.img}
						alt="maininmage"
						className="h-full w-full object-cover object-top"
					/>

					{/* main title goes here */}
					<div className="group absolute inset-0 flex flex-col items-center justify-center gap-6 bg-cyan-800 bg-opacity-50">
						<div className="text-3xl font-bold text-white max-sm:px-2 lg:text-4xl">
							{i18n.language === "ta" ? event?.titleTa : event?.title}
						</div>

						<div className="duration-400 h-1 w-28 rounded-full bg-white transition-all group-hover:w-44"></div>
					</div>
				</div>

				{/* main content goes here */}
				<div className="py-12 md:py-16">
					<div className="flex flex-col gap-3 md:gap-6">
						{/* // main title goes here */}
						<div className="text-2xl font-bold text-slate-900 max-sm:px-2 md:text-3xl">
							{i18n.language === "ta" ? event?.titleTa : event?.title}
						</div>
						{event?.description && (
							<ParaText
								aling="text-justify   "
								width="w-full max-sm:px-2"
								text={
									i18n.language === "ta"
										? event?.descriptionTa ??
										  " Description not available in Tamil "
										: event?.description
								}
							/>
						)}
						<div className="flex justify-center">
							<div className="mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
								{event?.otherImg?.map((img, index) => (
									<GalleryCard key={index} url={img} />
								))}
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default SingleEvent;
