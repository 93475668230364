import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { adminRequest } from "../../requestMethods";
import GalleryCard from "../GalleryCard";

type Props = {};
interface GalleryType {
	_id: string;
	img: string;
	title?: string;
}

function GalleryAdminPage({}: Props) {
	const [galleries, setGalleries] = useState<GalleryType[]>([]);

	const getGalleries = async () => {
		try {
			const { data } = await adminRequest.get("/gallery");
			setGalleries(data.data);
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);

			console.log(err);
		}
	};
	useEffect(() => {
		getGalleries();
	}, []);

	const deleteGallery = async (id: string) => {
		let ans = prompt("Are you sure you want to delete this gallery?");
		if (ans?.toLowerCase() != "yes") return;

		try {
			const { data } = await adminRequest.delete(`/gallery/${id}`);
			toast.success(data.message);
			//filter out the deleted gallery which has id not equal to the id of the deleted gallery
			setGalleries((prev) => prev.filter((gallery) => gallery._id !== id));
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);

			console.log(err);
		}
	};

	return (
		<div className="">
			{/* Gallery Card */}
			<div className="flex justify-center">
				<div className="mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					{galleries.length > 0 &&
						galleries.map((gallery) => (
							<div className="relative w-fit">
								<div
									onClick={() => deleteGallery(gallery._id)}
									className="absolute right-3 top-3 z-10 flex cursor-pointer flex-col rounded-sm bg-white px-2 py-1 text-sm text-rose-600 transition-all hover:bg-rose-600 hover:text-white"
								>
									Delete
								</div>

								<GalleryCard
									key={gallery._id}
									text={gallery.title}
									url={gallery.img}
								/>
							</div>
						))}
					{galleries.length == 0 && (
						<div className="text-2xl text-rose-600">No Gallery Found</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default GalleryAdminPage;
