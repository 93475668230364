import React from "react";
import { EventBox } from "./EventBox";

import { EventType } from "../pages/EventsPage";
import { useTranslation } from "react-i18next";

interface Props {
	events: EventType[];
}

const Events = ({ events }: Props) => {
	const { t, i18n } = useTranslation();

	return (
		<div className="flex justify-center">
			<div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 xl:gap-4">
				{events.length > 0 &&
					events?.map((event, index) => (
						<EventBox
							key={index}
							id={event._id}
							img={event.img}
							heading={
								i18n.language === "ta"
									? event.titleTa ?? " Title not available in Tamil "
									: event.title
							}
							date={event.date}
							para={
								i18n.language === "ta"
									? event.descriptionTa ??
									  " Description no available in Tamil "
									: event.description
							}
						/>
					))}
				{events.length == 0 && (
					<h1 className="text-center text-2xl">No Events</h1>
				)}
			</div>
		</div>
	);
};

export default Events;
