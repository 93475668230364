import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, ScrollRestoration } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
//modal
import LoadingModal from "./components/modal/LoadingModal";

import InitialTransition from "./components/animation/InitialTransition";
import ScrollTop from "./components/ScrollTop";
import Modal from "./components/modal/Modal";
import Signin from "./pages/Signin";
import { useUserStore } from "./states/userState";
import Admin from "./pages/Admin";
import Gallery from "./pages/Gallery";
import SingleEvent from "./pages/SingleEvent";
import Trans from "./pages/Trans";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
const Programs = lazy(() => import("./pages/Programs"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const EventsPage = lazy(() => import("./pages/EventsPage"));
const GetInvolvedPage = lazy(() => import("./pages/GetInvolvedPage"));
const Donate = lazy(() => import("./pages/Donate"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
	//scroll to top when the page  refreshing
	window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	};
	const { token } = useUserStore();

	return (
		<div className="App ">
			{/* donate modal */}
			<Modal
				heading="Donate"
				subHeading="Scan the QR code to donate"
				body={
					<div className="h-full">
						<img
							className="h-full rounded-lg object-contain"
							src="/donate/qr-code.png"
							alt="Donate with Qr code button"
						/>
					</div>
				}
			/>
			{/* scrollto top when the route is changing */}
			<ScrollTop />
			{/* initial InitialTransition */}
			<InitialTransition />
			<Navbar />
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path="/about"
					element={
						<Suspense fallback={<LoadingModal />}>
							<About />
						</Suspense>
					}
				/>
				<Route
					path="/events"
					element={
						<Suspense fallback={<LoadingModal />}>
							<EventsPage />
						</Suspense>
					}
				/>
				<Route
					path="/events/:id"
					element={
						<Suspense fallback={<LoadingModal />}>
							<SingleEvent />
						</Suspense>
					}
				/>
				<Route
					path="/programs"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Programs />
						</Suspense>
					}
				/>
				<Route
					path="/get-involved"
					element={
						<Suspense fallback={<LoadingModal />}>
							<GetInvolvedPage />
						</Suspense>
					}
				/>

				<Route
					path="/donate"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Donate />
						</Suspense>
					}
				/>
				<Route
					path="/contact"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Contact />
						</Suspense>
					}
				/>
				<Route
					path="/gallery"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Gallery />
						</Suspense>
					}
				/>
				<Route
					path="/dashboard/*"
					element={
						<Suspense fallback={<LoadingModal />}>
							{token ? <Dashboard /> : <Signin />}
						</Suspense>
					}
				/>
				{/* not found page */}
				<Route
					path="*"
					element={
						<Suspense fallback={<LoadingModal />}>
							<NotFound />
						</Suspense>
					}
				/>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
