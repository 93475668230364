import axios, { AxiosResponse } from "axios";
import { toast } from "react-hot-toast";

export const uploadImages = async (
	files: File[],
	setUploadedImage: any,
	path: string
) => {
	// Push all the axios request promise into a single array
	files.map((file, index) => {
		// check if the file size is greater than 10mb give toast error
		if (file.size > 10000000) {
			toast.error("Image size is too large, Max size is 10MB");
			return;
		}

		// Initial FormData
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", process.env.REACT_APP_CLOUD_PRESET!); //preset name

		// what to add path to cloudinary
		formData.append("folder", `penn-womanity${path}`);

		axios
			.post(process.env.REACT_APP_CLOUDINARY_URL!, formData)
			.then((response) => {
				const data = response.data;
				const fileURL = data.secure_url;
				setUploadedImage((prev: any) => [...prev, fileURL]);
				if (files.length === 1) {
					toast.success("Image uploaded successfully");
				} else {
					toast.success(`Image ${index + 1} uploaded successfully`);
				}

				return response;
			})
			.catch((err) => {
				toast.error("Image upload failed");
				console.log(err);
			});
	});
};
