import React from "react";
import { twMerge } from "tailwind-merge";
interface ContainerProps {
	children: React.ReactNode;
	className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
	return (
		<div
			className={twMerge(
				"mx-auto max-w-[2520px] px-4 sm:px-2 md:px-16 xl:px-32 ",
				className
			)}
		>
			{children}
		</div>
	);
};

export default Container;
