import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";

// get the hosted link
const getHostedLink = () => {
	const url = new URL(window.location.href);
	const baseURL = `${url.protocol}//${url.host}`;
	return baseURL;
};

i18n
	.use(i18nBackend)
	.use(initReactI18next)
	.init({
		lng: "en",
		fallbackLng: "en",

		interpolation: {
			escapeValue: false,
		},

		backend: {
			loadPath: `${getHostedLink()}/i18n/{{lng}}.json`,
		},
	});

export default i18n;
