import React from "react";

interface Props {
	text: string;
	className?: string;
}

const TextBox: React.FC<Props> = ({ text, className = "border-[1px]" }) => {
	return (
		<div
			className={`
        w-fit
        rounded-full 
		${className}
		 whitespace-nowrap border-iceBlue bg-blueGray pl-4 pr-2 text-darkGrey
		 `}
		>
			{text}
		</div>
	);
};

export default TextBox;
