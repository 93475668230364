import React from "react";
interface Props {
	text: string;
	aling?: string;
	textColor?: string;
	width?: string;
	className?: string;
}
const ParaText: React.FC<Props> = ({
	aling = "text-left",
	text,
	width = "max-w-[700px]",
	textColor = "text-bluishGrey",
	className,
}) => {
	return (
		<p
			className={`
	
    ${aling}

    ${width}
    ${textColor}
	${className}

    `}
		>
			{text}
		</p>
	);
};

export default ParaText;
