import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

//react router dom 6
import { BrowserRouter } from "react-router-dom";

//react hot toast
import { Toaster } from "react-hot-toast";

//i18n.ts
import "./i18n";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
			<Toaster
				position="top-right"
				toastOptions={{
					duration: 3000,
				}}
			/>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
