import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useModalStore } from "../../states/useModal";
import ParaText from "../ParaText";
import Button from "../button/Button";
type Props = {
	heading: string;
	body: React.ReactElement;
	subHeading?: string;
};

function Modal({ heading, body, subHeading }: Props) {
	const { isModal, toggleModal } = useModalStore();

	return (
		<>
			<Transition appear show={isModal} as={Fragment}>
				<Dialog
					as="div"
					className="relative "
					onClose={() => {
						alert(
							" Please scan the QR code to donate.Your Contribution will help us to help others.  "
						);
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 z-40 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="text-center text-xl font-medium leading-6 text-aquaBlue "
									>
										{heading}
										{subHeading && (
											<ParaText
												text={subHeading}
												aling="center"
												className="text-sm font-light"
											/>
										)}
									</Dialog.Title>
									<div className="mt-2 h-[70vh] overflow-y-auto md:h-[60vh] ">
										<div className="flex h-full w-full items-center justify-center">
											{body}
										</div>
									</div>

									<div className="ml-2 mt-4">
										<Button
											text="Close"
											className="text-sm"
											onClick={() => toggleModal(isModal)}
											outline
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default Modal;
