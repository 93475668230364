import React from "react";
import Input from "../components/input/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { adminRequest } from "../requestMethods";
import { useUserStore } from "../states/userState";
type Props = {};

function Signin({}: Props) {
	const { setAuth } = useUserStore();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>();

	const onSubmit: SubmitHandler<FieldValues> = async (userData) => {
		// adminRequest
		try {
			const { data } = await adminRequest.post("/auth/signin", userData);
			setAuth(data.user, data.token);

			// set local storage
			const authData = {
				token: data.token,
				user: JSON.stringify(data.user),
			};
			localStorage.setItem("auth", JSON.stringify(authData));
			// refresh the page
			window.location.reload();
			toast.success("Login Successfully!");
		} catch (error) {
			const errMsg = (error as any).message;
			errMsg ? toast.error(errMsg) : toast.error("Something went wrong!");
		}
	};

	return (
		<div className="flex h-screen items-center justify-center bg-gradient-to-r from-green-400 to-blue-500">
			<div className="h-fit w-96 space-y-8 rounded-lg bg-white px-6 py-16 shadow-lg">
				<div>
					<h1 className="text-center text-3xl font-semibold text-aquaBlue">
						Sign In
					</h1>
				</div>
				<form
					className="flex flex-col gap-4"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Input
						register={register}
						errors={errors}
						id={"email"}
						name="email"
						type="email"
						label="Email"
						required
					/>
					<Input
						register={register}
						errors={errors}
						id={"password"}
						name="password"
						type="password"
						label="Password"
						required
					/>
					<button
						type="submit"
						className="cursor-pointer rounded-lg bg-aquaBlue px-4 py-2 text-lg font-semibold text-white"
					>
						Sign In
					</button>
				</form>
			</div>
		</div>
	);
}

export default Signin;
