import React, { useState, useEffect } from "react";
import { adminRequest } from "../../requestMethods";
import { EventType } from "../../pages/EventsPage";
import { toast } from "react-hot-toast";
import Events from "../Events";

type Props = {};

function EventsAdminPage({}: Props) {
	const [events, setEvents] = useState<EventType[]>([]);

	const getAllEvents = async () => {
		try {
			const { data } = await adminRequest.get("/events");
			setEvents(data.data);
		} catch (err: any) {
			toast.error(err.response.data.error.message);
			console.log(err);
		}
	};

	useEffect(() => {
		getAllEvents();
	}, []);

	return (
		<div>
			<Events events={events} />
		</div>
	);
}

export default EventsAdminPage;
