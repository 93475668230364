import React, { useEffect, useState, useCallback } from "react";
import Container from "../Container";
//icons
import { HiChevronDown } from "react-icons/hi";
import { AiFillCaretRight } from "react-icons/ai";
import Button from "../button/Button";
import { Link, useNavigate } from "react-router-dom";
import SidebarLink from "./SidebarLink";
import { useUserStore } from "../../states/userState";
import { useTranslation } from "react-i18next";

const Navbar = () => {
	const { t, i18n } = useTranslation();
	const [isMenu, setIsMenu] = useState(false);

	const { user, token, removeAuth } = useUserStore();
	const navigate = useNavigate();

	const handleLogout = useCallback(() => {
		localStorage.removeItem("auth");
		removeAuth();
	}, [user, token]);

	// set language
	const setLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<header>
			<div className=" fixed left-0 right-0 top-0 z-40 h-[70px] bg-white shadow">
				<div className="relative">
					<Container>
						<div>
							<div className="flex items-center justify-between py-4 ">
								<div className="flex items-center gap-4">
									<div
										onClick={() => setIsMenu(false)}
										className="z-50 flex items-center"
									>
										<Link to={"/"}>
											<img
												className="h-9 w-9 rounded-lg"
												src="/logo.jpeg"
											/>
										</Link>
									</div>
									<div className="hidden h-[1px] w-[33px] rotate-90 bg-stoneGrey md:block "></div>
									<div className="hidden items-center gap-4 text-bluishGrey md:flex">
										<div>
											<div
												className=" group relative
										flex cursor-pointer items-center rounded-full border-[1px] border-iceBlue bg-blueGray pl-4 pr-2 text-darkGrey"
											>
												<span>{t("Who We are")}</span>
												<HiChevronDown
													className="-mb-1 text-bluishGrey"
													size={20}
												/>

												<div className="absolute -right-1 top-6 z-40 hidden rounded-sm bg-blueGray py-1 group-hover:block ">
													<div>
														<Link to={"/about"}>
															<div className="cursor-pointer px-4 py-2 hover:bg-iceBlue hover:text-darkGrey">
																{t("About Us")}
															</div>
														</Link>
													</div>
													<div>
														<Link to={"/programs"}>
															<div className="cursor-pointer px-4 py-2 hover:bg-iceBlue hover:text-darkGrey">
																{t("What We do")}
															</div>
														</Link>
													</div>
												</div>
											</div>
										</div>

										<Link to={"/events"}>
											<span>{t("Events")}</span>
										</Link>
										{/* <Link to={"/get-involved"}>
											<span>Get Involved</span>
										</Link> */}
										<Link to={"/gallery"}>
											<span>{t("Make them Happy!")}</span>
										</Link>
										<Link to={"/contact"}>
											<span>{t("Contact")}</span>
										</Link>
									</div>
								</div>

								<div className="z-50 flex items-center gap-2">
									{/* if lng is not eng then show this  */}
									{i18n.language == "en" ? (
										<span
											onClick={() => setLanguage("ta")}
											className="mx-1 cursor-pointer hover:text-aquaBlue hover:underline"
										>
											தமிழ்
										</span>
									) : (
										<span
											onClick={() => setLanguage("en")}
											className="mx-1 cursor-pointer hover:text-aquaBlue hover:underline"
										>
											English
										</span>
									)}

									<Button
										onClick={() => {
											navigate("/donate");
											setIsMenu(false);
										}}
										text={t("Donate")}
									/>
									{user && token && (
										<div
											onClick={handleLogout}
											className="mx-1 hidden md:block"
										>
											<Button outline text={t("Logout")} />
										</div>
									)}
									{/* Menu icon */}
									<div className="-mr-2 flex items-center md:hidden">
										<button
											onClick={() => setIsMenu(!isMenu)}
											className="group relative"
										>
											<div
												className={`relative flex h-[40px] w-[40px] transform items-center justify-center overflow-hidden rounded-full  
												 ring-gray-300 ring-opacity-30 transition-all duration-200 hover:ring-8
											${isMenu ? "ring-4" : "ring-0"}
											`}
											>
												<div className="flex h-[20px] w-[20px] origin-center transform flex-col justify-between overflow-hidden transition-all duration-300">
													<div
														className={`h-[2px] w-7 origin-left transform bg-slate-700 transition-all delay-100 duration-300
													
													 ${isMenu ? "translate-y-6" : "translate-y-0"}
													 `}
													></div>
													<div
														className={`h-[2px] w-7 transform rounded bg-slate-700 transition-all delay-75 duration-300 
													
													${isMenu ? "translate-y-6" : "translate-y-0"}
													`}
													></div>
													<div
														className={`h-[2px] w-7 origin-left transform bg-slate-700 transition-all duration-300
													${isMenu ? "translate-y-6" : "translate-y-0"}`}
													></div>

													<div
														className={`absolute top-2.5 flex w-0  transform items-center justify-between transition-all duration-500
													 ${isMenu ? " w-12 translate-x-0" : "-translate-x-10"}
													 `}
													>
														<div
															className={`absolute h-[2px] w-5 transform bg-slate-700 transition-all delay-300 duration-500 
														${isMenu ? "rotate-45" : "rotate-0"}
														`}
														></div>
														<div
															className={`absolute h-[2px] w-5  transform bg-slate-700 transition-all delay-300 duration-500
														 ${isMenu ? "-rotate-45" : "-rotate-0"}
														
														`}
														></div>
													</div>
												</div>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
				{/* sidebar for small screens */}
				<div
					className={`absolute  top-0 z-40 h-screen  w-full  bg-white transition-all duration-200
				md:-translate-y-full
				${isMenu ? "translate-y-0" : "-translate-y-full"}
									`}
				>
					<div className="h-full pt-16 ">
						<Container className="h-full ">
							<div className="h-full ">
								<div className="group px-4 py-1">
									<div className="flex items-center gap-1 ">
										<span className="transition-all duration-200 group-hover:rotate-90 ">
											<AiFillCaretRight />
										</span>
										<div className="w-full cursor-pointer rounded-sm py-2 text-lg text-darkGrey transition-all duration-200">
											{t("Who We are")}
										</div>
									</div>
									<div className="h-auto ">
										<SidebarLink
											setIsMenu={setIsMenu}
											url="/about"
											text={t("About Us")}
										/>
										<SidebarLink
											setIsMenu={setIsMenu}
											url="/events"
											text={t("Events")}
										/>
									</div>
								</div>
								<SidebarLink
									setIsMenu={setIsMenu}
									url="/gallery"
									text={t("Make them Happy!")}
								/>
								<SidebarLink
									setIsMenu={setIsMenu}
									url="/programs"
									text={t("What We do")}
								/>
								<SidebarLink
									setIsMenu={setIsMenu}
									url="/contact"
									text={t("Contact")}
								/>
								<SidebarLink
									setIsMenu={setIsMenu}
									url="/donate"
									text={t("Donate")}
								/>
								{user && token && (
									<div
										onClick={handleLogout}
										className="cursor-pointer rounded-sm px-4 py-2 text-2xl font-medium text-darkGrey transition-all duration-200 hover:bg-lightGrey hover:opacity-80"
									>
										{t("Logout")}
									</div>
								)}
							</div>
						</Container>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
