import React from "react";

import { ThreeDots } from "react-loader-spinner";

type Props = {};

function LoadingModal({}: Props) {
	return (
		<div className="relative bottom-0 left-0 right-0 top-0 flex h-screen w-screen items-center justify-center bg-white bg-opacity-50 ">
			<div>
				<ThreeDots
					height="80"
					width="80"
					radius="9"
					color="#15AABE"
					visible={true}
				/>
			</div>
		</div>
	);
}

export default LoadingModal;
