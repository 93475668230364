import React, { useRef, useState } from "react";
import UploadImage from "../cloudinary/UploadImage";
import Input from "../input/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "../button/Button";
import { toast } from "react-hot-toast";
import { adminRequest } from "../../requestMethods";

type Props = {};

function CreateGalleries({}: Props) {
	const formRef = useRef<HTMLFormElement>(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>();

	const [img, setImg] = useState([]);

	const createGallery: SubmitHandler<FieldValues> = async (data) => {
		if (!img.length) return toast.error("Image is required");
		const formatedData = {
			...data,
			img: img[0],
		};
		try {
			const { data } = await adminRequest.post("/gallery/create", {
				...formatedData,
				img: img[0],
			});
			setImg([]);
			formRef.current?.reset();
			toast.success(data.message);
		} catch (err: any) {
			console.log(err);
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
		}
	};

	return (
		<div className="w-full space-y-2 md:w-3/4 lg:w-2/3">
			<div className="px-[2%] text-3xl font-bold text-aquaBlue">
				Create Gallery
			</div>
			<div className="flex flex-col gap-4">
				{/* main Image */}
				<div className="relative flex h-72 w-96 items-center justify-center rounded-lg ">
					{img?.length > 0 ? (
						<div className="absolute left-0 top-0 h-full w-full bg-gray-100 bg-opacity-50 ">
							<img
								className="h-full w-full object-cover"
								src={img[0]}
								alt={img[0]}
							/>
						</div>
					) : (
						<div className="z-10 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-80 ">
							<UploadImage
								multiple={false}
								setUploadedImg={setImg}
								path="/gallery"
							/>
						</div>
					)}
				</div>

				{/* Form */}
				<form
					ref={formRef}
					onSubmit={handleSubmit(createGallery)}
					className="flex flex-col gap-4"
				>
					<Input
						register={register}
						errors={errors}
						id={"title"}
						name="title"
						type="text"
						label="Gallery Title"
					/>
					<Button
						type="submit"
						className="p-2 text-base hover:bg-opacity-90"
						text="Send"
					/>
				</form>
			</div>
		</div>
	);
}

export default CreateGalleries;
