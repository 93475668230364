import React, { useState } from "react";
import CirculatDot from "./CirculatDot";

const AnimatedDots = () => {
	const [dots, setDots] = useState(42);

	return (
		<div
			className=" flex max-w-[150px] flex-col  gap-4 opacity-50
    "
		>
			<div className="flex w-full justify-end  gap-4">
				<div className=" ml-auto grid w-fit grid-cols-6 gap-4">
					<CirculatDot bgColor="bg-transparent" />
					<CirculatDot bgColor="bg-transparent" />
					<CirculatDot />
					<CirculatDot />
					<CirculatDot />
					<CirculatDot />
				</div>
			</div>

			<div className="grid grid-cols-6 gap-4">
				{dots &&
					Array(dots)
						.fill(0)
						.map((_, index) => <CirculatDot key={index} />)}
			</div>
			<div className="flex w-full justify-end  gap-4">
				<div className=" ml-auto grid w-fit grid-cols-6 gap-4">
					<CirculatDot />
					<CirculatDot />
					<CirculatDot />
					<CirculatDot />
					<CirculatDot bgColor="bg-transparent" />
					<CirculatDot bgColor="bg-transparent" />
				</div>
			</div>
		</div>
	);
};

export default AnimatedDots;
