import React from "react";

const NotFound = () => {
	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
			<h1 className="mb-6 text-6xl font-bold text-red-600">404 Not Found</h1>
			<p className="text-xl text-gray-600">
				Oops! The page you are looking for does not exist.
			</p>
		</div>
	);
};

export default NotFound;
