// create user state
import { create } from "zustand";

interface userType {
	_id: string;
	email: string;
	isAdmin: boolean;
}

interface authStore {
	user: {
		_id: string;
		email: string;
		isAdmin: boolean;
	} | null;
	token: string;
	setAuth: (user: userType, token: string) => void;
	removeAuth: () => void;
}

// const authData = {
// 	token: data.token,
// 	user: JSON.stringify(data.user),
// };
// localStorage.setItem("auth", JSON.stringify(authData));
//extract value form local storage and set the values in initial state
const authData = localStorage.getItem("auth");
const auth = authData ? JSON.parse(authData) : { token: "", user: null };

const initialState = {
	user: JSON.parse(auth.user),
	token: auth.token,
};

export const useUserStore = create<authStore>((set) => ({
	...initialState,
	setAuth: (user: userType | null, token: string) => set({ user, token }),
	removeAuth: () => set({ user: null, token: "" }),
}));
