import React, { useEffect, useState } from "react";

import { IconType } from "react-icons/lib";
import SubscribeBtn from "./button/SubscribeBtn";

interface Props {
	text?: string;
	placeholder?: string;
	icon?: IconType;
	outline?: boolean;
	shadow?: string;
	handleChange?: boolean;
	submitHandler?: (query: string) => void;
}

const SubscribeBox: React.FC<Props> = ({
	text = "Subscribe",
	placeholder = "Enter your email",
	icon: Icon,
	outline,
	submitHandler,
	handleChange,
	shadow = "shadow-sm",
}) => {
	const [value, setValue] = useState<string>("");

	const handleClick = () => {
		submitHandler && submitHandler(value);
		!handleChange && setValue("");
	};

	useEffect(() => {
		if (handleChange) {
			submitHandler && submitHandler(value);
		}
	}, [value]);

	return (
		<div
			className={`flex w-full max-w-[700px] items-center justify-center  bg-white p-4 sm:rounded-2xl
		${shadow}
	`}
		>
			<div className="flex w-full items-center justify-center ">
				<input
					value={value}
					onChange={(e) => setValue(e.target.value)}
					type="email"
					placeholder={placeholder}
					className="min-w-[10px] flex-1 p-2 placeholder-iceBlue outline-none"
				/>
				<SubscribeBtn
					handleClick={handleClick}
					text={text}
					outline={outline}
					icon={Icon}
				/>
			</div>
		</div>
	);
};

export default SubscribeBox;
