import React from "react";

type Props = {
	text: string;
	className?: string;
};

function SemiboldText({ text, className }: Props) {
	return (
		<div
			className={`font-semibold text-white
    ${className}
    `}
		>
			{text}
		</div>
	);
}

export default SemiboldText;
