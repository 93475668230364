import React from "react";
// import { FileUploader } from "react-drag-drop-files";
import { uploadImages } from "./upload";
import { toast } from "react-hot-toast";

type Props = {
	setUploadedImg: any;
	path: string;
	multiple?: boolean;
};

function UploadImage({ setUploadedImg, path, multiple = true }: Props) {
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return toast.error("No files selected");

		const files: File[] = Array.from(e.target.files);
		if (e.target.files) {
			await uploadImages(files, setUploadedImg, path);
		} else {
			toast.error("No files selected");
		}
	};

	return (
		<div>
			<input
				type="file"
				accept="image/*"
				multiple={multiple}
				onChange={handleFileChange}
			/>

			{/* <FileUploader
				multiple
				label="Drag and drop images here"
				handleChange={async (files: File[]) => {
					await uploadImages(files, setuploadedImage);
				}}
				types={acceptedImageTypes}
				maxSize={10}
			/> */}
		</div>
	);
}

export default UploadImage;
