import React from "react";
import { motion } from "framer-motion";
interface ButtonProps {
	type?: "submit" | "reset" | "button";
	text?: string;
	outline?: boolean;
	className?: string;
	onClick?: () => void;
	disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	text = "Submit",
	type = "button",
	outline,
	className,
	onClick,
	disabled,
}) => {
	return (
		<motion.button
			type={type}
			disabled={disabled}
			onClick={onClick}
			className={` rounded-lg border-[1px] px-4
        py-1 font-semibold
        ${outline ? " border-iceBlue" : "border-aquaBlue"}
        ${outline ? "bg-white" : "bg-aquaBlue"}
        ${outline ? "text-darkGrey" : "text-white"}
        ${className}

        `}
		>
			{text}
		</motion.button>
	);
};

export default Button;
