import React, { useState } from "react";
import Container from "../Container";
import EventsAdminPage from "./EventsAdminPage";
import GalleryAdminPage from "./GalleryAdminPage";
import SponsorsAdminPage from "./SponsorsAdminPage";
import { AiFillCaretRight } from "react-icons/ai";

type Props = {
	user: any;
};

function Home({ user }: Props) {
	const [isEvent, setIsEvent] = useState(true);
	const [isGallery, setIsGallery] = useState(false);
	const [isSponsor, setIsSponsor] = useState(false);

	return (
		<div className="space-y-8">
			{/* header div start */}
			<div className="flex flex-col justify-between gap-5 md:flex-row md:items-start md:gap-10 ">
				<div className="flex w-full flex-col ">
					<div className="text-2xl font-semibold text-gray-400">
						Welcome
					</div>
					{user && (
						<div className="text-grey text-2xl font-semibold">
							{user?.email}
						</div>
					)}
				</div>
			</div>
			{/* //////////////////////////////////////////// Header div end */}
			{/* events */}
			<div className="flex flex-col gap-2 ">
				<div
					onClick={() => setIsEvent(!isEvent)}
					className="flex cursor-pointer items-center gap-1 pl-2"
				>
					<span className="text-lg">Events</span>
					<span
						className={`text-xl transition-all duration-300 ${
							isEvent && "rotate-90"
						} `}
					>
						<AiFillCaretRight className="text-xl text-gray-400 " />
					</span>
				</div>
				{isEvent && <EventsAdminPage />}
			</div>

			<div className="flex flex-col gap-2">
				<div
					onClick={() => setIsGallery(!isGallery)}
					className="flex cursor-pointer items-center gap-1 pl-2"
				>
					<span className="text-lg">Galleries</span>
					<span
						className={`text-xl transition-all duration-300 ${
							isGallery && "rotate-90"
						} `}
					>
						<AiFillCaretRight className="text-xl text-gray-400 " />
					</span>
				</div>
				{isGallery && <GalleryAdminPage />}
			</div>

			<div className="flex flex-col gap-2">
				<div
					onClick={() => setIsSponsor(!isSponsor)}
					className="flex cursor-pointer items-center gap-1 pl-2"
				>
					<span className="text-lg">Sponsors</span>
					<span
						className={`text-xl transition-all duration-300 ${
							isSponsor && "rotate-90"
						} `}
					>
						<AiFillCaretRight className="text-xl text-gray-400 " />
					</span>
				</div>
				{isSponsor && <SponsorsAdminPage />}
			</div>
		</div>
	);
}

export default Home;
