import axios from "axios";
import { useUserStore } from "./states/userState";
const LOCAL_URL = "http://localhost:8000/api";
const DEPLOYED_URL = "https://cloudy-pink-clam.cyclic.app/api";

// const initialState = {
// 	user: auth.user,
// 	token: auth.token,
// };

// export const useUserStore = create<authStore>((set) => ({
// 	...initialState,
// 	setAuth: (user: userType | null, token: string) => set({ user, token }),
// 	removeAuth: () => set({ user: null, token: "" }),
// }));

//extract token from useUserStore

const TOKEN = useUserStore.getState().token;

export const adminRequest = axios.create({
	baseURL: DEPLOYED_URL,

	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${TOKEN}`,
	},
});
