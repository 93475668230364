import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { EventType } from "./EventsPage";
import { adminRequest } from "../requestMethods";
import { toast } from "react-hot-toast";
import Input from "../components/input/Input";
import TextArea from "../components/input/TextArea";
import Button from "../components/button/Button";
import { MdDelete } from "react-icons/md";
import UploadImage from "../components/cloudinary/UploadImage";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

type Props = {};

function UpdateEvent({}: Props) {
	const formRef = useRef<HTMLFormElement>(null);
	const [event, setEvent] = React.useState<EventType | null>(null);
	const [mainImg, setMainImg] = useState<string[]>([]);
	const [otherImgs, setOtherImgs] = useState([]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>({
		values: {
			title: event?.title || "", // Default value for title field
			titleTa: event?.titleTa || "", // Default value for titleTa field
			description: event?.description || "", // Default value for description field
			descriptionTa: event?.descriptionTa || "", // Default value for descriptionTa field
			date: event?.date || "", // Default value for date field
		},
	});

	// get the event id from the params
	const { id } = useParams<{ id: string }>();
	//getsingle event from backend
	const getEvent = async (id: string) => {
		//get single event from backend
		try {
			const { data } = await adminRequest.get(`/events/${id}`);
			setEvent(data.data);
			setMainImg([data.data.img]);
			setOtherImgs(data.data.otherImg);
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
			console.log(err);
		}
	};
	useEffect(() => {
		if (id) getEvent(id);
	}, [id]);

	const updateEvent: SubmitHandler<FieldValues> = async (data) => {
		if (!mainImg.length) {
			toast.error("Main Image is required");
		}

		try {
			// events/update/:id
			const res = await adminRequest.put(`/events/update/${id}`, {
				...data,
				img: mainImg[0],
				otherImg: otherImgs,
			});
			//reset the form
			// setMainImg([]);
			// setOtherImgs([]);
			// formRef.current?.reset();
			if (id) getEvent(id);
			toast.success(res.data.message);
		} catch (err: any) {
			console.log(err);
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
		}
	};
	const handleDelete = (img: string) => {
		setOtherImgs((prev) => prev.filter((i) => i !== img));
	};

	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
			<div className="w-full space-y-2 md:w-[90%] lg:w-2/3">
				<div className="px-[2%] text-3xl font-bold text-aquaBlue">
					Update Post
				</div>
				<div className="flex flex-col gap-4">
					{/* main Image */}
					<div className="relative flex h-72 w-96 items-center justify-center rounded-lg ">
						{mainImg?.length > 0 ? (
							<div className="absolute left-0 top-0 h-full w-full ">
								<div className="group relative h-full w-full">
									<img
										className="h-full w-full object-cover"
										src={mainImg[0]}
										alt={mainImg[0]}
									/>
									<div
										onClick={() => setMainImg([])}
										className="absolute right-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center bg-rose-300 bg-opacity-30 transition-all group-hover:flex "
									>
										<MdDelete className="text-4xl text-rose-700" />
									</div>
								</div>
							</div>
						) : (
							<div className="z-10 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-80 ">
								<UploadImage
									multiple={false}
									setUploadedImg={setMainImg}
									path="/events"
								/>
							</div>
						)}
					</div>
					{/* other Images */}
					<div className="flex flex-col gap-2">
						<UploadImage setUploadedImg={setOtherImgs} path="/events" />
						<div className="flex flex-wrap gap-2">
							{otherImgs.map((img, i) => (
								<div key={i} className="h-28 w-28 rounded-sm">
									<div className="group relative h-full w-full">
										<img
											src={img}
											className="h-full w-full object-cover"
											alt={img}
										/>
										<div
											onClick={() => handleDelete(img)}
											className="absolute right-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center bg-rose-300 bg-opacity-30 transition-all group-hover:flex "
										>
											<MdDelete className="text-5xl text-rose-700" />
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					{/* Form */}
					<form
						ref={formRef}
						onSubmit={handleSubmit(updateEvent)}
						className="flex flex-col gap-4"
					>
						<Input
							register={register}
							errors={errors}
							id={"title"}
							name="title"
							type="text"
							label="Event Title"
							required
						/>
						<Input
							register={register}
							errors={errors}
							id={"titleTa"}
							name="titleTa"
							type="text"
							label="Event Title in Tamil"
						/>
						<Input
							register={register}
							errors={errors}
							id={"date"}
							name="date"
							type="text"
							label="01-07-2023"
							required
						/>
						<TextArea
							required
							name="description"
							id={"description"}
							register={register}
							errors={errors}
							label="Description"
						/>
						<TextArea
							name="descriptionTa"
							id={"descriptionTa"}
							register={register}
							errors={errors}
							label="Description in Tamil"
						/>
						<Button
							type="submit"
							className="p-2 text-base hover:bg-opacity-90"
							text="Update Event"
						/>
					</form>
				</div>
			</div>
		</div>
	);
}

export default UpdateEvent;
