import React from "react";
import { animate, motion } from "framer-motion";

type Props = {};

const AquaBox = {
	initial: {
		height: "100vh",
		bottom: 0,
		zIndex: 1000,
	},
	animate: {
		height: 0,
		zIndex: 1000,
		transition: {
			when: "afterChildren",
			duration: 1.5,
			ease: [0.87, 0, 0.13, 1],
		},
		//hide it after trnasition end
		transitionEnd: {
			display: "none",
		},
	},
};

const textContainer = {
	initial: {
		opacity: 1,
	},
	animate: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.25,
			when: "afterChildren",
		},
		// hide after delay of 0.25 sec
		transitionEnd: {
			display: "none",
		},
	},
};

const text = {
	initial: {
		y: 40,
	},
	animate: {
		y: 80,
		transition: {
			duration: 1.5,
			ease: [0.87, 0, 0.13, 1],
		},
		transitionEnd: {
			display: "none",
		},
	},
};
// for hiding it after the delay of 1.5sec
const outerDiv = {
	initial: {
		opacity: 1,
		zIndex: 1000,
	},
	animate: {
		opacity: 1,
		zIndex: 1000,
		transition: {
			duration: 3.25,
			ease: [0.87, 0, 0.13, 1],
		},
		transitionEnd: {
			display: "none",
		},
	},
};

function InitialTransition({}: Props) {
	return (
		<motion.div
			variants={outerDiv}
			initial="initial"
			animate="animate"
			className="absolute inset-0 flex items-center justify-center "
		>
			<motion.div
				className="relative z-50 flex w-full items-center justify-center bg-aquaBlue"
				initial="initial"
				animate="animate"
				variants={AquaBox}
			>
				<motion.svg variants={textContainer} className="absolute flex">
					<pattern
						id="pattern"
						patternUnits="userSpaceOnUse"
						width={750}
						height={800}
						className="text-white"
					>
						<rect className="h-full w-full fill-current" />
						<motion.rect
							variants={text}
							className="h-full w-full fill-current text-gray-600"
						/>
					</pattern>
					<text
						className="text-4xl font-bold"
						textAnchor="middle"
						x="50%"
						y="50%"
						style={{ fill: "url(#pattern)" }}
					>
						Penn-Womanity
					</text>
				</motion.svg>
			</motion.div>
		</motion.div>
	);
}

export default InitialTransition;
