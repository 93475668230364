import React from "react";
import { Link } from "react-router-dom";

type Props = {
	url: string;
	text: string;
	setIsMenu: (isMenu: boolean) => void;
};

function SidebarLink({ url = "/", text = "Link", setIsMenu }: Props) {
	const handleClick = () => {
		setIsMenu(false);
	};
	return (
		<Link onClick={handleClick} to={url}>
			<div className="cursor-pointer rounded-sm px-4 py-2 text-2xl font-medium text-darkGrey transition-all duration-200 hover:bg-lightGrey hover:opacity-80">
				{text}
			</div>
		</Link>
	);
}

export default SidebarLink;
