import React, { useRef, useState } from "react";
import Container from "../Container";
import UploadImage from "../cloudinary/UploadImage";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Input from "../input/Input";
import TextArea from "../input/TextArea";
import Button from "../button/Button";
import { toast } from "react-hot-toast";
import { adminRequest } from "../../requestMethods";
import { MdDelete } from "react-icons/md";

type Props = {};

function CreateEvents({}: Props) {
	const formRef = useRef<HTMLFormElement>(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>();
	const [mainImg, setMainImg] = useState([]);
	const [otherImgs, setOtherImgs] = useState([]);

	const createEvent: SubmitHandler<FieldValues> = async (data) => {
		if (!mainImg.length) {
			toast.error("Main Image is required");
		}
		try {
			const res = await adminRequest.post("/events/create", {
				...data,
				img: mainImg[0],
				otherImg: otherImgs,
			});
			setMainImg([]);
			setOtherImgs([]);
			formRef.current?.reset();
			toast.success(res.data.message);
		} catch (err: any) {
			console.log(err);
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);
		}
	};

	const handleDelete = (img: string) => {
		setOtherImgs((prev) => prev.filter((i) => i !== img));
	};

	return (
		<div className="w-full space-y-2 md:w-[90%] lg:w-2/3">
			<div className="px-[2%] text-3xl font-bold text-aquaBlue">
				Create Post
			</div>
			<div className="flex flex-col gap-4">
				{/* main Image */}
				<div className="relative flex h-72 w-96 items-center justify-center rounded-lg ">
					{mainImg?.length > 0 ? (
						<div className="absolute left-0 top-0 h-full w-full ">
							<div className="group relative h-full w-full">
								<img
									className="h-full w-full object-cover"
									src={mainImg[0]}
									alt={mainImg[0]}
								/>
								<div
									onClick={() => setMainImg([])}
									className="absolute right-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center bg-rose-300 bg-opacity-30 transition-all group-hover:flex "
								>
									<MdDelete className="text-4xl text-rose-700" />
								</div>
							</div>
						</div>
					) : (
						<div className="z-10 flex h-full w-full items-center justify-center bg-gray-100 bg-opacity-80 ">
							<UploadImage
								multiple={false}
								setUploadedImg={setMainImg}
								path="/events"
							/>
						</div>
					)}
				</div>
				{/* other Images */}
				<div className="flex flex-col gap-2">
					<UploadImage setUploadedImg={setOtherImgs} path="/events" />
					<div className="flex flex-wrap gap-2">
						{otherImgs.map((img, i) => (
							<div key={i} className="h-28 w-28 rounded-sm">
								<div className="group relative h-full w-full">
									<img
										src={img}
										className="h-full w-full object-cover"
										alt={img}
									/>
									<div
										onClick={() => handleDelete(img)}
										className="absolute right-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center bg-rose-300 bg-opacity-30 transition-all group-hover:flex "
									>
										<MdDelete className="text-5xl text-rose-700" />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* Form */}
				<form
					ref={formRef}
					onSubmit={handleSubmit(createEvent)}
					className="flex flex-col gap-4"
				>
					<Input
						register={register}
						errors={errors}
						id={"title"}
						name="title"
						type="text"
						label="Event Title"
						required
					/>
					<Input
						register={register}
						errors={errors}
						id={"titleTa"}
						name="titleTa"
						type="text"
						label="Event Title in Tamil"
						required
					/>
					<Input
						register={register}
						errors={errors}
						id={"date"}
						name="date"
						type="text"
						label="01-07-2023"
						required
					/>
					<TextArea
						required
						name="description"
						id={"description"}
						register={register}
						errors={errors}
						label="Description"
					/>
					<TextArea
						required
						name="descriptionTa"
						id={"descriptionTa"}
						register={register}
						errors={errors}
						label="Description in Tamil"
					/>
					<Button
						type="submit"
						className="p-2 text-base hover:bg-opacity-90"
						text="Send"
					/>
				</form>
			</div>
		</div>
	);
}

export default CreateEvents;
