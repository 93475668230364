import React from "react";
import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";

interface TextAreaProps {
	id: string;
	name?: string;
	required?: boolean;
	disabled?: boolean;
	label?: string;
	register: UseFormRegister<FieldValues>;
	errors: FieldErrors;
}

const TextArea: React.FC<TextAreaProps> = ({
	label = "Message",
	name = "message",
	register,
	errors,
	required,
	disabled,
	id,
}) => {
	return (
		<div className="relative mb-3 w-full" data-te-input-wrapper-init>
			<textarea
				disabled={disabled}
				rows={5}
				{...register(name, { required: required })}
				id={id}
				placeholder={label}
				className={`block w-full rounded-sm border
		
				 px-3  pt-3
				 ${
						errors[id]
							? " border-rose-500  "
							: " border-lightGrey focus:border-aquaBlue "
					}


				  focus:outline-none`}
			></textarea>
		</div>
	);
};

export default TextArea;
