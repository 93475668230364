import React from "react";

import { IconType } from "react-icons/lib";
//frame motion
import { motion } from "framer-motion";
interface Props {
	text?: string;
	icon?: IconType;
	outline?: boolean;
	handleClick?: () => void;
}

const SubscribeBtn: React.FC<Props> = ({
	text = "Subscribe",
	icon: Icon,
	outline,
	handleClick,
}) => {
	// button varient to scale to 1.1 on hover for infinite time
	const buttonVariant = {
		hover: {
			scale: [1, 1.1, 1],
			transition: {
				duration: 0.3,
				ease: "easeInOut",
				yoyo: Infinity,
			},
		},
	};

	return (
		<motion.button
			onClick={handleClick}
			className={` flex cursor-pointer items-center rounded-xl
            border-[1px]  px-6
         py-3
         text-lg
         ${outline ? "bg-white" : "  bg-darkGrey"}
         ${outline ? "text-darkGrey" : "text-white"}
            ${outline ? "border-iceBlue" : "border-darkGrey"}

         `}
		>
			{text}
			{Icon && <Icon size={20} className="ml-2 inline-block" />}
		</motion.button>
	);
};

export default SubscribeBtn;
