//using zustaand create a stare isModal and a function to set it
import { create } from "zustand";

interface IModalStore {
	isModal: boolean;
	toggleModal: (value: boolean) => void;
}

export const useModalStore = create<IModalStore>((set) => ({
	isModal: false,
	//togglemodal function
	toggleModal: (value) => set(() => ({ isModal: !value })),
}));
