import React, { useState, useEffect } from "react";
import Hero from "../components/about/Hero";
import SubscribeBox from "../components/SubscribeBox";
import GalleryCard from "../components/GalleryCard";
import { adminRequest } from "../requestMethods";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

type Props = {};

interface GalleryType {
	_id: string;
	img: string;
	title?: string;
}

function Gallery({}: Props) {
	const { t } = useTranslation();

	const [galleries, setGalleries] = useState<GalleryType[]>([]);
	const [allGalleries, setallGalleries] = useState<GalleryType[]>([]);

	const getGalleries = async () => {
		try {
			const { data } = await adminRequest.get("/gallery");
			setGalleries(data.data);
			setallGalleries(data.data);
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);

			console.log(err);
		}
	};

	const filterGalleries = (query: string) => {
		//filter events and set state based on the query
		if (!query) return setGalleries(allGalleries);
		const filteredGalleries = allGalleries.filter((gallery) => {
			query = query.toLowerCase();
			const galleryTitle = gallery.title?.toLowerCase();
			return galleryTitle?.includes(query);
		});

		setGalleries(filteredGalleries);
	};
	useEffect(() => {
		getGalleries();
	}, []);

	return (
		<>
			<div className="relative ">
				<Hero heading={t("Make them Happy!")} />
				<div className="absolute bottom-0 z-30 flex w-full justify-center ">
					<SubscribeBox
						handleChange={true}
						submitHandler={(query) => filterGalleries(query)}
						placeholder={t("Search Gallery")}
						shadow="shadow-md"
						text={t("Search")}
					/>
				</div>
			</div>
			<div className="py-12 md:py-16">
				{/* Gallery Card */}
				<div className="flex justify-center">
					<div className="mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
						{galleries.length > 0 &&
							galleries.map((gallery) => (
								<GalleryCard
									key={gallery._id}
									text={gallery.title}
									url={gallery.img}
								/>
							))}
					</div>
				</div>
			</div>
		</>
	);
}

export default Gallery;
