import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { adminRequest } from "../../requestMethods";
import { MdDelete } from "react-icons/md";

type Props = {};

interface SponsorsType {
	_id: string;
	img: string;
	name?: string;
	nameTa?: string;
}

function SponsorsAdminPage({}: Props) {
	const [sponsors, setSponsors] = useState<SponsorsType[]>([]);

	const getSponsors = async () => {
		try {
			const { data } = await adminRequest.get("/sponsors");
			setSponsors(data.data);
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);

			console.log(err);
		}
	};

	useEffect(() => {
		getSponsors();
	}, []);

	const deleteSponsor = async (id: string) => {
		let ans = prompt("Are you sure you want to delete this sponsor?");
		if (ans?.toLowerCase() != "yes") return;
		try {
			const { data } = await adminRequest.delete(`/sponsors/${id}`);
			toast.success(data.message);
			//filter out the deleted sponsor which has id not equal to the id of the deleted sponsor
			setSponsors((prev) => prev.filter((sponsor) => sponsor._id !== id));
		} catch (err: any) {
			toast.error(
				err?.response?.data?.error?.message ||
					err?.message ||
					"Some Error Occured"
			);

			console.log(err);
		}
	};
	return (
		<div className="py-12 md:py-16">
			<div className="flex w-full flex-col items-center justify-between gap-4 p-8 md:flex-row ">
				{sponsors.length > 0 &&
					sponsors.map((sponsor) => (
						<div
							key={sponsor._id}
							className="flex flex-col items-center gap-2 "
						>
							{/* <div className="absolute top-0 right-0 w-full h-full bg-rose-600">
							d
						</div> */}
							<div className="group relative w-fit ">
								<img
									src={sponsor.img}
									alt={sponsor.name}
									className="h-28 w-28 rounded-full border-4 border-aquaBlue object-cover md:h-16 md:w-16"
								/>
								<div
									onClick={() => deleteSponsor(sponsor._id)}
									className="absolute right-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center rounded-full bg-rose-500 bg-opacity-30 transition-all group-hover:flex "
								>
									<MdDelete className="text-4xl text-rose-700" />
								</div>
							</div>
							<span>{sponsor.name}</span>
						</div>
					))}
				{sponsors.length === 0 && (
					<div className="text-2xl text-gray-400">
						No Sponsors Added Yet
					</div>
				)}
			</div>
		</div>
	);
}

export default SponsorsAdminPage;
