import React from "react";
import { IconType } from "react-icons/lib";
import ParaText from "../ParaText";
import AnimatedDots from "../AnimatedDots";

type Props = {
	heading: string;
	text?: string;
	icon?: IconType;
};

function Hero({ heading, text, icon: Icon }: Props) {
	return (
		<div className="relative z-20 h-[450px] overflow-hidden ">
			<div className="to-darkBlue absolute left-0 right-0 top-0 -z-10 -ml-10 h-[420px]  origin-top-left -rotate-3 bg-gradient-to-r from-aquaBlue to-electricBlue"></div>
			<div className="absolute 	left-0 right-0 top-8 -z-20 -ml-10 h-[420px] origin-top-left  -rotate-3 bg-lightSkyBlue"></div>
			<div className="absolute left-4 top-[20%] -z-10 md:left-[5%]">
				<AnimatedDots />
			</div>

			<div className="flex h-full flex-col items-center justify-center gap-4">
				<div className="flex items-center justify-center gap-4 whitespace-nowrap ">
					<div className="group whitespace-normal text-3xl font-bold md:text-4xl xl:text-5xl">
						{heading}
					</div>
					{Icon && (
						<Icon
							size={56}
							className="rotate-120 group-hover:translate-x-2 "
						/>
					)}
				</div>
				{text && <ParaText text={text} aling="text-center" />}
			</div>
		</div>
	);
}

export default Hero;
