import React from "react";
import { AiTwotoneHeart } from "react-icons/ai";

type Props = {
	url: string;
	text?: string;
};

function GalleryCard({ url, text }: Props) {
	return (
		<div className="group relative h-[420px] w-full max-w-[340px] ">
			{/* <div className="absolute z-10 flex items-center gap-4 p-2 bg-gray-100 right-3 top-3 bg-opacity-40">
				<span>12</span>
				<span>
					<AiTwotoneHeart className="text-xl text-red-500 cursor-pointer " />
				</span>
			</div> */}
			<a target="_blank" href={url} rel="noreferrer">
				<img
					loading="lazy"
					alt={"Image"}
					src={url}
					className="h-full w-full object-cover opacity-90 transition-opacity group-hover:opacity-100"
				/>
			</a>

			<div className="absolute bottom-0 left-0 right-0 overflow-hidden ">
				{text && (
					<div className="translate-y-8 transform bg-gray-100 bg-opacity-60 p-2 opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100 ">
						<p className="text-base font-medium uppercase tracking-widest text-slate-900 ">
							{text.slice(0, 40)}
						</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default GalleryCard;
