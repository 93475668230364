import React from "react";
import Container from "../Container";
import SubscribeBox from "../SubscribeBox";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import SemiboldText from "./SemiboldText";
import ParaText from "../ParaText";
import {
	AiFillInstagram,
	AiOutlineFacebook,
	AiOutlineInstagram,
	AiOutlineTwitter,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";

type Props = {};

function Footer({}: Props) {
	const { t } = useTranslation();
	return (
		<div className="relative mt-24 bg-darkGrey py-8 md:py-12 ">
			{/* {<div className="absolute w-full -top-16 md:flex md:items-center md:justify-center">
				<div className="w-full py-2 bg-aquaBlue sm:py-4 md:w-fit md:rounded-lg lg:rounded-2xl">
					<div className="flex flex-col items-center justify-between w-full h-full gap-4 md:flex-row md:pr-4">
						<div className="px-4 text-2xl font-semibold text-white xl:text-3xl">
							Empowering women. transforming lives.
						</div>
						<SubscribeBox icon={BsArrowRight} />
					</div>
				</div>
			</div>} */}
			<Container>
				<div className="mt-12 flex flex-col items-stretch justify-between gap-6 border-b-2 border-stoneGrey pb-4 text-stoneGrey md:mt-6 md:flex-row md:gap-2 ">
					{/* //TODO:  leftFooter */}
					<div className="flex flex-col ">
						<div className="">
							<Link to={"/"}>
								<img
									className="h-12 w-12 rounded-lg"
									src="/logo.jpeg"
								/>
							</Link>
						</div>
						<div className="mt-6">
							<SemiboldText text="Penn Womanity" />
							<ParaText
								text="11 Arivarasan Salai
								Ranganathan Nagar
								Porur
								Chennai 600116"
								width="max-w-[440px]"
								textColor="text-stoneGrey"
							/>
							<div className="flex items-start gap-2 ">
								<span>{t("Contact Us:")}</span>
								<div className="flex flex-col justify-center">
									<SemiboldText
										text="
								+91 9840037183"
									/>
									<SemiboldText
										text="
								+91 9840664713"
									/>
								</div>
							</div>
							<div className="flex items-center gap-2 ">
								<span>{t("Email Id:")}</span>
								<SemiboldText text="penn.womanity@gmail.com" />
							</div>
						</div>
					</div>

					{/* right footer */}
					<div>
						<div className="flex h-full w-full flex-col justify-between ">
							<div className="flex flex-wrap items-center gap-4 text-white sm:justify-between">
								<Link to={"/about"}>
									<span>{t("About Us")}</span>
								</Link>
								<Link to={"/programs"}>
									<span>{t("What We do")}</span>
								</Link>
								<Link to={"/events"}>
									<span>{t("Events")}</span>
								</Link>
								<Link to={"/gallery"}>
									<span>{t("Make them Happy!")}</span>
								</Link>
								<Link to={"/contact"}>
									<span>{t("Contact")}</span>
								</Link>
								<Link to={"/donate"}>
									<span>{t("Donate")}</span>
								</Link>
							</div>
							{/* social links */}
							<div className="mt-8 flex gap-12 sm:justify-end">
								<div className="transition-all hover:scale-125">
									<a
										target="_blank"
										href="https://www.instagram.com/pennamaipu19/?igshid=MzRlODBiNWFlZA%3D%3D"
									>
										<AiOutlineInstagram className="text-3xl text-white" />
									</a>
								</div>
								<div className="transition-all hover:scale-125">
									<a
										target="_blank"
										href="https://m.facebook.com/groups/409086889885444/"
									>
										<AiOutlineFacebook className="text-3xl text-white" />
									</a>
								</div>
								<div className="transition-all hover:scale-125">
									<a href="https://twitter.com/PENNamaipu2019?t=1YKdZq4k4B9LI4dcxQmY9g&s=08">
										<AiOutlineTwitter className="text-3xl text-white" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Footer;
