import React, { useEffect, useState } from "react";
import { useUserStore } from "../states/userState";
import UploadImage from "../components/cloudinary/UploadImage";
import { Route } from "react-router-dom";
import CreateEvents from "../components/admin/CreateEvents";
import Container from "../components/Container";
import CreateGalleries from "../components/admin/CreateGalleries";
import AddSponsor from "../components/admin/AddSponsor";
import Home from "../components/admin/Home";
import { useNavigate } from "react-router-dom";

type Props = {};

const sidebar = [
	{
		name: "Home",
	},
	{
		name: "Create Event",
	},
	{
		name: "Create Gallery",
	},
	{
		name: "Add Sponsor",
	},
];

function Admin({}: Props) {
	const { token, user } = useUserStore();
	const [selectedItem, setSelectedItem] = useState(sidebar[0].name);
	const navigate = useNavigate();

	useEffect(() => {
		if (!token || !user) {
			navigate("/");
		}
	}, [user, token]);

	return (
		<Container className="md:pl-16 md:pr-0 lg:pl-32 lg:pr-0 xl:px-32">
			<div className="flex min-h-screen pt-6 ">
				{/* sidebar */}
				<div className="flex h-screen w-56 flex-col bg-gray-50 text-lg">
					{sidebar.map((item, index) => (
						<div
							onClick={() => setSelectedItem(item.name)}
							key={index}
							className={`${
								item.name == selectedItem
									? "border-l-4 border-aquaBlue bg-white text-aquaBlue"
									: "border-l-0 hover:border-l-4 hover:bg-white"
							} cursor-pointer p-2 transition-all  `}
						>
							{item.name}
						</div>
					))}
				</div>

				<div className="flex h-full w-full flex-col px-4">
					{sidebar[0].name === selectedItem && <Home user={user} />}
					{sidebar[1].name === selectedItem && <CreateEvents />}
					{sidebar[2].name === selectedItem && <CreateGalleries />}
					{sidebar[3].name === selectedItem && <AddSponsor />}
				</div>
			</div>
		</Container>
	);
}

export default Admin;
