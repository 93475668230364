import { UseFormRegister, FieldValues, FieldErrors } from "react-hook-form";

interface Props {
	id: string;
	type?: string;
	name?: string;
	required?: boolean;
	disabled?: boolean;
	label?: string;
	register: UseFormRegister<FieldValues>;
	errors: FieldErrors;
}

const Input: React.FC<Props> = ({
	type = "text",
	name = "input",
	disabled,
	label = "Input",
	register,
	errors,
	required,
	id,
}) => {
	return (
		<div className="relative mb-3 w-full" data-te-input-wrapper-init>
			<label
				htmlFor="UserEmail"
				className={`relative 
				block overflow-hidden rounded-md
				 border border-lightGrey px-3
				  pt-3 shadow-sm focus-within:ring-1
				 ${
						errors[id]
							? " border-rose-500  focus:border-rose-500  "
							: "focus-within:border-aquaBlue focus-within:ring-aquaBlue"
					}
					
					`}
			>
				<input
					id={id}
					type={type}
					{...register(name, { required: required })}
					name={name}
					disabled={disabled}
					placeholder=" "
					className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
				/>

				<span
					className={`absolute start-3 top-3
				 -translate-y-1/2 text-xs
				 ${errors[id] ? " text-rose-500 " : " text-gray-700"}
				 
				  transition-all peer-placeholder-shown:top-1/2
				   peer-placeholder-shown:text-sm peer-focus:top-3
				    peer-focus:text-xs`}
				>
					{label}
				</span>
			</label>
		</div>
	);
};

export default Input;
