import React from "react";
import TextBox from "./TextBox";
import ParaText from "./ParaText";
import { useNavigate } from "react-router-dom";

interface Props {
	img: string;
	para?: string;
	heading: string;
	date: string;
	id: string;
}

export const EventBox: React.FC<Props> = ({ id, img, para, heading, date }) => {
	const navigate = useNavigate();

	return (
		<div
			onClick={() => navigate(`/events/${id}`)}
			className="relative flex min-h-[392px] max-w-[400px] cursor-pointer  flex-col gap-1 bg-white  p-2 shadow-sm transition-all hover:-translate-y-1 hover:shadow-md"
		>
			<TextBox text={date} className="absolute left-4 top-4 bg-opacity-90" />

			<img
				className="h-[300px] w-full rounded-xl object-cover"
				src={img}
				alt="event image"
			/>

			<div className="text-xl font-semibold">{heading}</div>
			{para && <ParaText text={para.slice(0, 100)} />}
		</div>
	);
};
