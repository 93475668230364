import React, { Suspense } from "react";
import { Route, Router, Routes } from "react-router-dom";
import Admin from "./Admin";
import UpdateEvent from "./UpdateEvent";
import LoadingModal from "../components/modal/LoadingModal";
import NotFound from "./NotFound";

type Props = {};

function Dashboard({}: Props) {
	return (
		<div>
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<LoadingModal />}>
							<Admin />
						</Suspense>
					}
				/>
				<Route
					path="/update-event/:id"
					element={
						<Suspense fallback={<LoadingModal />}>
							<UpdateEvent />
						</Suspense>
					}
				/>
				{/* also create not found page here */}

				<Route
					path="/*"
					element={
						<Suspense fallback={<LoadingModal />}>
							<NotFound />
						</Suspense>
					}
				/>
			</Routes>
		</div>
	);
}

export default Dashboard;
